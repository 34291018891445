import { AddressRow } from '../../../components/shared/RAFAddress/AddressRow';
import { propertyOf } from '../../../RAFComponents/helpers/utils';
import { RAFEntityBase } from '../../../RAFComponents/models/Common/RAFEntityBase';
import { ILookupRow } from '../../../RAFComponents/models/CompositeTypes/ILookupRow';

//export class AccountRow extends RAFEntityBase implements ILookupRow{
export class IAccountDto {
    //Id?: number;
    UID?: string;
    AccountName?: string;
    Website?: string;
    Email?: string;
    Phone?: string;

    Description?: string;
    AccountType?: string;
    Industry?: string;
    NoOfEmployees?: string;

    IntegrationRef?:string;
    IntegrationRefUID?: string;

    AddressLine1?: string;
    AddressLine2?: string;
    AddressPostalCode?: string;
    AddressCityUID?: string;
    AddressCity?: string;
    AddressStateUID?: string;
    AddressState?: string;
    AddressCountryUID?: string;
    AddressCountry?: string;

    AccountId?: string;
    TagsListJson?: string[];
    BusinessProcess?: string;
    BusinessProcessUID?: string;
    ProcessStatus?: string;

    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    LastActivityDate?: Date;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    //AclId?: number;
    Count?: number;
    ExtraInfoJson?: any;
    Portal?:string;
    PortalUID?:string;
    BpStep?:string;
    BpStepUID?:string;

}
export class IAccountRow {
    //Id?: number;
    UID?: string;
    AccountName?: string;
    Website?: string;
    Email?: string;
    Phone?: string;

    Description?: string;
    AccountType?: string;
    Industry?: string;
    NoOfEmployees?: string;

    IntegrationRef?:string;
    IntegrationRefUID?: string;

    AddressLine1?: string;
    AddressLine2?: string;
    AddressPostalCode?: string;
    AddressCityUID?: string;
    AddressCity?: string;
    AddressStateUID?: string;
    AddressState?: string;
    AddressCountryUID?: string;
    AddressCountry?: string;

    AccountId?: string;
    TagsListJson?: string[];
    BusinessProcess?: string;
    BusinessProcessUID?: string;
    ProcessStatus?: string;

    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    LastActivityDate?: Date;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    //AclId?: number;
    Count?: number;
    ExtraInfoJson?: any;
    Portal?:string;
    PortalUID?:string;
    BpStep?:string;
    BpStepUID?:string;
}

export class AccountRow extends IAccountRow implements RAFEntityBase, ILookupRow {
    AddressList?: AddressRow[];

    getClassName?() { //use entityname
        return 'account';
    }
    getIdField?(): string {
        return propertyOf<AccountRow>("UID");
    }
    getListUrl?(): string {//use route name(entity displayname)
        return "Account/List";
    }
    getSaveUrl?(): string {//use route name(entity displayname)
        return "Account/Save";
    }
    getGroupByUrl?(): string {
        return "Account/GroupBy";
    }
    getLookupUrl(): string {//use route name(entity displayname)
        return "Account/Lookup";
    }
    isOptionCreatable?(): boolean {
        return true;
    }

}
