import { PropsWithChildren, useContext, useEffect } from "react";
import { FormRenderProps } from "react-final-form";
import RAFLookupCC from "../../../RAFComponents/Inputs/RAFLookupCC";
import {
  RAFDefaultFieldProps,
  RAFFormContext,
  RAFLookupFieldProps,
  checkAndRenderContent,
} from "../../../RAFComponents/Inputs/RFFUtils";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  IsNullOrWhiteSpace,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import RAFLookUpMUI from "../../../RAFComponents/Inputs/RAFLookUpMUI";

interface IProps {
  fieldName?: string;
}

function ACContactLookup<T>({
  field,
  label,
  width,
  type,
  onChanged,
  fieldName,
  SearchCreateOptionMode,
  showCreateButton,
  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  //children,
  ...props
}: PropsWithChildren<RAFLookupFieldProps<T> & IProps>) {
  //const [divKey, setDivKey] = useState(Math.random());
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
  let account = "";
  let accountUID = "";
  let customFilter: RAFCustomFilter = {};
  let createFormInitialValues = null;
  if (
    isNotNullAndUndefined(rafFormContextValue) &&
    isNotNullAndUndefined(rafFormContextValue.values)
  ) {
    account = rafFormContextValue.values[fieldName];
    accountUID = rafFormContextValue.values[fieldName + "UID"];

    if (isNotNullAndUndefined(account) && isNotNullAndUndefined(accountUID)) {
      createFormInitialValues = [
        { key: fieldName, value: account },
        { key: fieldName + "UID", value: accountUID },
      ];
    }
    customFilter.Condition = "and";
    customFilter.Rules = [];
    if (!IsNullOrWhiteSpace(accountUID)) {
      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];
      filterVal.push(accountUID);
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = fieldName + "UID";
      customFilter.Rules.push(filter);
    } else {
      customFilter = null;
    }
    //setDivKey(Math.random());
  }

  useEffect(() => {
    const onChangeAccount = () => { };
    onChangeAccount();
  });

  return (
    <div>
      <div className="row align-items-center" id={"rafdiv" + field.toString()}>
        <div className="col-12 d-flex">
          {showLabel && showLabel === true && (
            <label className={required ? "form-label required" : "form-label"}>
              <>{checkAndRenderContent(label) || checkAndRenderContent(field)}</>
            </label>
          )}
        </div>
        <div className="col-12 d-flex">
          <div className="w-100">
            <RAFLookUpMUI<T>
              key={rafFormContextValue.values[fieldName + "UID"]}
              field={field}
              label={label}
              SearchCreateOptionMode={SearchCreateOptionMode}
              showCreateButton={showCreateButton}
              required={required}
              showLabel={false}
              type={type}
              width={width && width !== null ? width : "100%"}
              disabled={disabled}
              validate={validate}
              onChanged={onChanged}
              customFilter={customFilter}
              createFormInitialValues={createFormInitialValues}
              {...props}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ACContactLookup;
